<template>
  <div>
    <h1 class="font-semibold text-xl mb-5">
      Настройки
      <i v-if="loading" class="el-icon-loading"></i>
    </h1>
    <el-tabs v-if="settings" type="border-card">
      <el-tab-pane label="Настройки магазина kaspi">
        <section v-loading="loading">
          <div class="mb-2">
            <p class="text-gray-500 mb-1">Название магазина (company)</p>
            <el-input v-model="settings.company" size="small"></el-input>
          </div>
          <div class="mb-3">
            <p class="text-gray-500 mb-1">ID партнера (merchantId)</p>
            <el-input v-model="settings.merchantId" size="small"></el-input>
          </div>
          <div class="mb-3">
            <p class="text-gray-500 mb-1">Токен kaspi</p>
            <el-input v-model="settings.kaspi_token" size="small"></el-input>
          </div>
          <div class="mb-3">
            <p class="text-gray-500 mb-1">Ссылка на xml файл kaspi</p>
            <p>{{ settings.kaspi_xml }}</p>
          </div>
          <el-button @click="saveSettings" :disabled="loading" size="small">
            Сохранить
          </el-button>
        </section>
      </el-tab-pane>
      <el-tab-pane label="Настройки магазина halyk">
        <section v-loading="loading">
          <div class="mb-2">
            <p class="text-gray-500 mb-1">Название магазина (company)</p>
            <el-input v-model="settings.halyk_company" size="small"></el-input>
          </div>
          <div class="mb-3">
            <p class="text-gray-500 mb-1">ID партнера (merchantId)</p>
            <el-input
              v-model="settings.halyk_merchantId"
              size="small"
            ></el-input>
          </div>
          <div class="mb-3">
            <p class="text-gray-500 mb-1">Бренд</p>
            <el-input v-model="settings.halyk_brand" size="small"></el-input>
          </div>
          <div class="mb-3">
            <p class="text-gray-500 mb-1">Ссылка на xml файл halyk</p>
            <p>{{ settings.halyk_xml }}</p>
          </div>
          <el-button @click="saveSettings" :disabled="loading" size="small">
            Сохранить
          </el-button>
        </section>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { getSettings, saveSettings } from "@/api/settings";

export default {
  name: "Settings",
  data: () => ({
    loading: false,
    settings: null,
  }),
  computed: {
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    await this.getSettings();
  },
  methods: {
    async getSettings() {
      this.loading = true;
      this.settings = await getSettings();
      this.loading = false;
    },
    async saveSettings() {
      try {
        this.loading = true;
        const result = await saveSettings(this.settings);
        if (result.success) {
          this.$message.success("Успешно");
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
