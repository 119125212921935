<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      Users
      <div class="flex items-center">
        <el-input v-model="email"></el-input>
        <el-button @click="createUser">Создать</el-button>
      </div>
    </h1>
    <el-table border :data="users" stripe style="width: 100%">
      <el-table-column
        align="center"
        prop="id"
        width="50"
        label="ID"
      ></el-table-column>
      <el-table-column prop="email" label="Email"></el-table-column>
      <el-table-column prop="xml_name" label="Xml">
        <template slot-scope="scope">
          <a
            target="_blank"
            class="text-blue-400 underline"
            :href="'https://api.formp.kz/xml/kaspi/' + scope.row.xml_name"
            >Ссылка на прайс</a
          >
        </template>
      </el-table-column>
      <el-table-column align="right" prop="limit" label="Лимит на дэмпинг">
      </el-table-column>
      <el-table-column
        align="right"
        prop="products_count"
        label="Кол-во продуктов"
      >
      </el-table-column>
      <el-table-column
        align="right"
        prop="branches_count"
        label="Кол-во точек продаж"
      >
      </el-table-column>
      <el-table-column
        align="right"
        prop="damping_count"
        label="Кол-во дэмпингов"
      >
      </el-table-column>
      <el-table-column prop="limit" label="Лимит">
        <template slot-scope="scope">
          <el-button
            @click="() => confirmDelete(scope.row.id)"
            icon="el-icon-delete"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getUsers, createUser, deleteUser } from "@/api/settings";

export default {
  name: "Users",
  data: () => ({
    users: [],
    email: null,
  }),
  async created() {
    await this.getUsers();
  },
  methods: {
    async getUsers() {
      this.users = await getUsers();
    },
    async createUser() {
      const result = await createUser(this.email);
      const message = `email: ${result.user.email}\npassword: ${result.user.password}`;
      this.$alert(message, "Пользователь создан", {
        confirmButtonText: "OK",
      });
    },
    confirmDelete(id) {
      this.$confirm("Удалить?", "Внимание", {
        confirmButtonText: "Да",
        cancelButtonText: "Отмена",
        type: "warning",
      }).then(async () => {
        await this.deleteUser(id);
      });
    },
    async deleteUser(userId) {
      try {
        const result = await deleteUser(userId);
        if (result.success) {
          this.$message.success("Удален");
          await this.getUsers();
        } else {
          this.$message.error("Ошибка");
        }
      } catch (e) {
        console.log(e);
        this.$message.error("Ошибка ", e);
      }
    },
  },
};
</script>

<style scoped></style>
