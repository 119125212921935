<template>
  <div>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      Офферы ({{ offers_count }})
    </h1>
    <el-table
      class="mb-10"
      border
      :data="offers"
      style="width: 100%"
      :row-class-name="tableRowClassName"
    >
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="dateformat" label="Время"></el-table-column>
      <el-table-column align="center" prop="total" label="Кол-во">
      </el-table-column>
      <el-table-column label="Процент">
        <template slot-scope="scope">
          {{ scope.row.total / offers_count }}
        </template>
      </el-table-column>
    </el-table>
    <h1 class="font-semibold text-xl mb-5 flex justify-between items-center">
      Ошибки ({{ total_errors }})
      <el-button @click="sync" :disabled="loading">
        Replace all proxies
      </el-button>
    </h1>
    <el-table
      border
      :data="proxies"
      style="width: 100%"
      :row-class-name="tableRowClassName"
    >
      <el-table-column align="center" type="index" width="50"></el-table-column>
      <el-table-column prop="ip" label="IP"></el-table-column>
      <el-table-column align="center" prop="count" label="Ошибки">
      </el-table-column>
      <el-table-column label="Replace">
        <template slot-scope="scope">
          <el-button
            v-if="scope.row.status === 0"
            :disabled="loading"
            @click="() => replace(scope.row.ip)"
            icon="el-icon-refresh"
            size="medium"
            plain
            type="primary"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import { getProxies, replaceProxy, syncProxies } from "@/api/settings";

export default {
  name: "Proxies",
  data: () => ({
    proxies: [],
    offers: [],
    offers_count: 0,
    total_errors: 0,
    loading: false,
  }),
  async created() {
    await this.getProxies();
  },
  methods: {
    async getProxies() {
      const {
        proxies,
        offers,
        total_offers,
        total_errors,
      } = await getProxies();
      this.proxies = proxies;
      this.offers_count = total_offers;
      this.offers = offers;
      this.total_errors = total_errors;
    },
    async replace(ip) {
      this.loading = true;
      const result = await replaceProxy(ip);
      if (result.success) {
        this.$message.success("Success");
        await this.getProxies();
      } else {
        this.$message.error("Error");
      }
      this.loading = false;
      console.log(result);
    },
    async sync() {
      this.loading = true;
      const result = await syncProxies();
      if (result.success) {
        this.$message.success("Success");
      } else {
        this.$message.error("Error");
      }
      this.loading = false;
      console.log(result);
    },
    tableRowClassName({ row }) {
      if (row.status === -1) {
        return "warning-row";
      }
      return "";
    },
  },
};
</script>

<style scoped></style>
