<template>
  <div>
    <h1 class="mb-10 flex justify-between items-center">
      <span class="text-xl font-semibold">Аналитика</span>
      <div class="flex justify-end">
        <div class="mr-2">
          <el-date-picker
            @change="getAnalytics"
            v-model="filters.date"
            type="daterange"
            align="right"
            range-separator="-"
            value-format="dd.MM.yyyy"
            format="dd.MM.yyyy"
            start-placeholder="Дата с"
            end-placeholder="Дата по"
          >
          </el-date-picker>
        </div>
        <div>
          <el-select
            v-model="filters.state"
            @change="getAnalytics"
            placeholder="Статус"
          >
            <el-option
              v-for="s in states"
              :key="s"
              :label="s"
              :value="s"
            ></el-option>
          </el-select>
        </div>
        <el-upload
          class="ml-2"
          :action="uploadUri"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleUploadSuccess"
          :before-upload="handleBeforeUpload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :data="{ type: 'file' }"
        >
          <el-button ref="upload" plain type="warning">
            Загрузить данные
          </el-button>
        </el-upload>
      </div>
    </h1>

    <div class="flex items-start justify-between mb-10">
      <div
        v-for="status in analytics_by_status"
        :key="status._id"
        class="border w-full p-3 rounded-md mr-3"
        :class="status._id"
      >
        <div class="text-xl">
          {{ statuses[status._id] || status._id }}
        </div>
        <div class="font-bold text-2xl mb-1">
          {{ numberFormat(status.total) }} тг
        </div>
        <div class="text-gray-400">
          Средний: {{ numberFormat(status.average) }} тг
        </div>
        <div class="text-gray-400">Кол-во: {{ status.count }}</div>
      </div>
    </div>

    <el-row>
      <el-col :span="17">
        <div id="container"></div>
      </el-col>
      <el-col :offset="1" :span="6">
        <div id="state"></div>
      </el-col>
    </el-row>
  </div>
</template>

<script>
import { getAnalytics, importAnalytics } from "@/api/analytics";
import Highcharts from "highcharts";
import { format, setDate } from "date-fns";
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Analytics",
  data: () => ({
    analytics_by_date: [],
    analytics_by_status: [],
    states: [],

    statuses: {
      APPROVED_BY_BANK: "Одобрен банком",
      ACCEPTED_BY_MERCHANT: "Принят на обработку продавцом",
      COMPLETED: "Завершён",
      CANCELLED: "Отменён",
      CANCELLING: "Ожидает отмены",
      KASPI_DELIVERY_RETURN_REQUESTED: "Ожидает возврата",
      RETURN_ACCEPTED_BY_MERCHANT: "Ожидает решения по возврату",
      RETURNED: "Возвращён",
    },

    filters: {
      date: null,
      state: null,
    },
    loading: true,
    uploadLoading: false,
    uploadUri: `${process.env.VUE_APP_DOMAIN}/api/upload`,
  }),
  computed: {
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
  },
  async created() {
    await this.getAnalytics();
  },
  methods: {
    async getAnalytics() {
      let filters = {};
      if (this.filters.date) {
        filters["start_date"] = this.filters.date[0];
        filters["stop_date"] = this.filters.date[1];
      } else {
        const start_date = format(setDate(new Date(), 1), "dd.MM.yyyy");
        const stop_date = format(new Date(), "dd.MM.yyyy");
        filters["start_date"] = start_date;
        filters["stop_date"] = stop_date;
        this.filters.date = [start_date, stop_date];
      }
      const loading = this.$loading({
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const result = await getAnalytics(filters);
      this.analytics_by_date = result.orders_by_date;
      this.analytics_by_status = result.orders_by_status;
      this.drawDailyChart();
      this.drawStateChart();
      loading.close();
    },
    numberFormat(number) {
      return new Intl.NumberFormat().format(Math.round(number));
    },
    drawDailyChart() {
      const sum = this.analytics_by_date.map((o) => [
        +format(new Date(o._id), "T"),
        o.total,
      ]);
      const count = this.analytics_by_date.map((o) => [
        +format(new Date(o._id), "T"),
        o.count,
      ]);
      Highcharts.chart("container", {
        chart: {
          type: "column",
        },
        title: {
          text: "Аналитика продаж по дням",
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        series: [
          {
            name: "Сумма продаж",
            data: sum,
            zIndex: 1,
            marker: {
              fillColor: "white",
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors[0],
            },
          },
          {
            name: "Кол-во продаж",
            data: count,
            zIndex: 1,
            marker: {
              fillColor: "white",
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors[3],
            },
          },
        ],
      });
    },
    drawStateChart() {
      const seriesData = this.analytics_by_status.map((o) => ({
        name: o._id,
        y: o.total,
        count: o.count,
      }));
      Highcharts.chart("state", {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: {
          text: "Аналитика продаж по статусам",
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            "Сумма: <b>{point.y} тг</b> <br> Кол-во: <b>{point.count}</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: "Продажи",
            colorByPoint: true,
            data: seriesData,
          },
        ],
      });
    },
    handleBeforeUpload() {
      this.uploadLoading = this.$loading({
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    async handleUploadSuccess(res) {
      try {
        const result = await importAnalytics(res.url);
        this.uploadLoading.close();
        if (result.success) {
          this.$message.success("Успешно");
          await this.getAnalytics();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
    },
    openFileDialog() {
      this.$nextTick(() => {
        this.$refs.upload.$el.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.CANCELLED {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
}
.COMPLETED {
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.RETURNED {
  color: #909399;
  background: #f4f4f5;
  border-color: #d3d4d6;
}
</style>
