import apiClient from "@/api/apiClient";

export const getSettings = async () => {
  const result = await apiClient.get("/api/settings");
  return result.data.settings;
};

export const saveSettings = async (settings) => {
  const result = await apiClient.post("/api/settings", settings);
  return result.data;
};

export const getProxies = async () => {
  const result = await apiClient.get("/api/proxies");
  return result.data;
};

export const replaceProxy = async (ip) => {
  const result = await apiClient.post("/api/proxies", {
    ip,
  });
  return result.data;
};

export const syncProxies = async () => {
  const result = await apiClient.post("/api/proxies", {
    action: "sync",
  });
  return result.data;
};

export const getUsers = async () => {
  const result = await apiClient.get("/api/users");
  return result.data.users;
};

export const createUser = async (email) => {
  const result = await apiClient.post("/api/users", {
    email,
  });
  return result.data;
};

export const deleteUser = async (user_id) => {
  const result = await apiClient.post("/api/users", {
    user_id,
    action: "delete",
  });
  return result.data;
};
