<template>
  <div>
    <h1 class="mb-10 flex justify-between items-center">
      <div class="text-xl font-semibold">
        Аналитика Kaspi Pay
        <p class="font-normal text-sm text-gray-400" v-if="user.last_updated">
          Last updated: {{ user.last_updated }}
        </p>
      </div>
      <div class="flex justify-end">
        <el-select
          class="mr-2"
          @change="changeFilters"
          v-model="filters.branch"
        >
          <el-option label="Все" :value="null"></el-option>
          <el-option
            v-for="x in branches"
            :key="x.branch"
            :label="x.branch"
            :value="x.branch"
          ></el-option>
        </el-select>
        <div class="mr-2">
          <el-date-picker
            @change="changeFilters"
            v-model="filters.date"
            type="daterange"
            align="right"
            range-separator="-"
            value-format="dd.MM.yyyy"
            format="dd.MM.yyyy"
            start-placeholder="Дата с"
            end-placeholder="Дата по"
          >
          </el-date-picker>
        </div>
        <el-upload
          :action="uploadUri"
          :headers="headers"
          :show-file-list="false"
          :on-success="handleUploadSuccess"
          :before-upload="handleBeforeUpload"
          accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
          :data="{ type: 'file' }"
        >
          <el-button ref="upload" plain type="warning">
            Загрузить данные
          </el-button>
        </el-upload>
      </div>
    </h1>

    <div class="flex items-start justify-between mb-10">
      <div
        v-for="type in types"
        :key="type.type"
        class="border w-full p-3 rounded-md mr-3"
        :class="typeTitles[type.type].cls"
      >
        <div class="text-xl">
          {{ typeTitles[type.type].title }}
        </div>
        <div class="font-bold text-2xl mb-1">
          {{ numberFormat(type.total) }} тг
        </div>
        <div class="text-gray-400">
          Комиссия: {{ numberFormat(type.tax) }} тг
        </div>
        <div class="text-gray-400">Кол-во: {{ type.count }}</div>
      </div>
    </div>
    <el-row>
      <el-col :span="17">
        <div id="container"></div>
      </el-col>
      <el-col :offset="1" :span="6">
        <div id="state"></div>
      </el-col>
    </el-row>
    <div class="flex mb-3 items-center justify-between">
      <div class="font-semibold text-xl">Точки продаж</div>
    </div>
    <el-table class="mb-10" :data="branches" border>
      <el-table-column
        type="index"
        width="50"
        align="center"
        label="N"
      ></el-table-column>
      <el-table-column label="Точка продаж" prop="branch"></el-table-column>
      <el-table-column align="right" label="Сумма продаж" prop="total">
        <template slot-scope="scope">
          {{ numberFormat(scope.row.total) }} тг
        </template>
      </el-table-column>
      <el-table-column align="right" label="Сумма комиссии" prop="tax">
        <template slot-scope="scope">
          {{ numberFormat(scope.row.tax) }} тг
        </template>
      </el-table-column>
      <el-table-column
        align="right"
        label="Кол-во"
        prop="count"
      ></el-table-column>
    </el-table>
    <div class="flex mb-3 items-center justify-between">
      <div class="font-semibold text-xl">Товары</div>
      <div>
        <span class="text-gray-500">Сортировать по:</span>
        <el-select class="ml-2" @change="changeFilters" v-model="filters.sort">
          <el-option
            v-for="(value, key) in sortOptions"
            :key="key"
            :value="key"
            :label="value"
          ></el-option>
        </el-select>
      </div>
    </div>
    <el-table border :data="products">
      <el-table-column
        align="center"
        label="N"
        type="index"
        width="50px"
      ></el-table-column>
      <el-table-column
        width="400px"
        prop="product"
        label="Продукт"
      ></el-table-column>
      <el-table-column align="right" prop="success_price" label="Сумма продаж">
        <template slot-scope="scope">
          {{ numberFormat(scope.row.success_price) }}
        </template>
      </el-table-column>
      <el-table-column
        align="right"
        prop="success_count"
        label="Кол-во продаж"
      ></el-table-column>
      <el-table-column
        align="right"
        prop="failed_price"
        label="Сумма возвратов"
      >
        <template slot-scope="scope">
          {{ numberFormat(scope.row.failed_price) }}
        </template>
      </el-table-column>
      <el-table-column
        align="right"
        prop="failed_count"
        label="Кол-во возвратов"
      ></el-table-column>
    </el-table>
    <el-pagination
      class="text-right my-5"
      background
      :disabled="loading"
      layout="prev, pager, next"
      :total="products_count"
      :page-size="10"
      :current-page.sync="filters.page"
      @current-change="getAnalytics"
    >
    </el-pagination>
  </div>
</template>

<script>
import { importKaspiPayAnalytics, getKaspiPayAnalytics } from "@/api/analytics";
import Highcharts from "highcharts";
import { format, setDate } from "date-fns";
import { numberFormat } from "@/helpers/formatter";
import LocalStorageService from "@/services/LocalStorageService";
const localStorageService = LocalStorageService.getService();

export default {
  name: "Analytics",
  data: () => ({
    analytics_by_date: [],
    analytics_by_status: [],
    products: [],
    products_count: 0,
    types: [],
    branches: [],
    typeTitles: {
      0: {
        title: "Продажа",
        cls: "sale",
      },
      1: {
        title: "Возврат",
        cls: "return",
      },
      2: {
        title: "Прибыль",
        cls: "profit",
      },
    },
    filters: {
      branch: null,
      date: null,
      page: 1,
      sort: null,
    },
    sortOptions: {
      "success_price DESC": "Сумма продаж - по убыванию",
      "success_price ASC": "Сумма продаж - по возрастанию",
      "success_count DESC": "Кол-во продаж - по убыванию",
      "success_count ASC": "Кол-во продаж - по возрастанию",
      "failed_price ASC": "Сумма возвратов - по убыванию",
      "failed_price DESC": "Сумма возвратов - по возрастанию",
      "failed_count DESC": "Кол-во возвратов - по убыванию",
      "failed_count ASC": "Кол-во возвратов - по возрастанию",
    },
    loading: false,
    uploadLoading: false,
    uploadUri: `${process.env.VUE_APP_DOMAIN}/api/upload`,
  }),
  computed: {
    headers() {
      const token = localStorageService.getAccessToken();
      return { Authorization: `Bearer ${token}` };
    },
    user() {
      return this.$store.state.user;
    },
  },
  async created() {
    await this.getAnalytics();
  },
  methods: {
    async changeFilters() {
      this.filters.page = 1;
      await this.getAnalytics();
    },
    async getAnalytics() {
      let filters = {
        page: this.filters.page,
        sort: this.filters.sort,
      };
      if (this.filters.date) {
        filters["start_date"] = this.filters.date[0];
        filters["stop_date"] = this.filters.date[1];
      } else {
        const start_date = format(setDate(new Date(), 1), "dd.MM.yyyy");
        const stop_date = format(new Date(), "dd.MM.yyyy");
        filters["start_date"] = start_date;
        filters["stop_date"] = stop_date;
        this.filters.date = [start_date, stop_date];
      }
      if (this.filters.branch) {
        filters["branch"] = this.filters.branch;
      }
      const loading = this.$loading({
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
      const result = await getKaspiPayAnalytics(filters);
      this.types = result.types;
      this.products = result.products;
      this.products_count = result.products_count;
      this.filters.sort = result.sort;
      this.branches = result.branches;
      // this.drawDailyChart();
      // this.drawStateChart();
      loading.close();
    },
    numberFormat(number) {
      return numberFormat(Math.round(number));
    },
    drawDailyChart() {
      const sum = this.analytics_by_date.map((o) => [
        +format(new Date(o._id), "T"),
        o.total,
      ]);
      const count = this.analytics_by_date.map((o) => [
        +format(new Date(o._id), "T"),
        o.count,
      ]);
      Highcharts.chart("container", {
        chart: {
          type: "column",
        },
        title: {
          text: "Аналитика продаж по дням",
        },
        xAxis: {
          type: "datetime",
        },
        yAxis: {
          title: {
            text: null,
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          crosshairs: true,
          shared: true,
        },
        series: [
          {
            name: "Сумма продаж",
            data: sum,
            zIndex: 1,
            marker: {
              fillColor: "white",
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors[0],
            },
          },
          {
            name: "Кол-во продаж",
            data: count,
            zIndex: 1,
            marker: {
              fillColor: "white",
              lineWidth: 2,
              lineColor: Highcharts.getOptions().colors[3],
            },
          },
        ],
      });
    },
    drawStateChart() {
      const seriesData = this.analytics_by_status.map((o) => ({
        name: o._id,
        y: o.total,
        count: o.count,
      }));
      Highcharts.chart("state", {
        chart: {
          plotBackgroundColor: null,
          plotBorderWidth: null,
          plotShadow: false,
          type: "pie",
        },
        title: {
          text: "Аналитика продаж по статусам",
        },
        credits: {
          enabled: false,
        },
        tooltip: {
          pointFormat:
            "Сумма: <b>{point.y} тг</b> <br> Кол-во: <b>{point.count}</b>",
        },
        accessibility: {
          point: {
            valueSuffix: "%",
          },
        },
        plotOptions: {
          pie: {
            allowPointSelect: true,
            cursor: "pointer",
            dataLabels: {
              enabled: false,
            },
            showInLegend: true,
          },
        },
        series: [
          {
            name: "Продажи",
            colorByPoint: true,
            data: seriesData,
          },
        ],
      });
    },
    handleBeforeUpload() {
      this.uploadLoading = this.$loading({
        lock: true,
        text: "Загрузка",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)",
      });
    },
    async handleUploadSuccess(res) {
      try {
        const result = await importKaspiPayAnalytics(res.url);
        this.uploadLoading.close();
        if (result.success) {
          this.$message.success("Успешно");
          await this.getAnalytics();
        } else {
          this.$message.error(`Ошибка ${result.message}`);
        }
      } catch (e) {
        this.$message.error(`Ошибка ${e.message}`);
      }
    },
    openFileDialog() {
      this.$nextTick(() => {
        this.$refs.upload.$el.click();
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.return {
  color: #f56c6c;
  background: #fef0f0;
  border-color: #fbc4c4;
}
.profit {
  color: #67c23a;
  background: #f0f9eb;
  border-color: #c2e7b0;
}
.sale {
  color: #909399;
  background: #f4f4f5;
  border-color: #d3d4d6;
}
</style>
